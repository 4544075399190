@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;

    --chart-1: theme(colors.blue.800);
    --chart-2: theme(colors.blue.600);
    --chart-3: theme(colors.blue.500);
    --chart-4: theme(colors.blue.400);
    --chart-5: theme(colors.blue.300);
    --chart-6: theme(colors.blue.200);
  }

  .dark {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;

    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;

    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;

    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;

    --border: 216 34% 17%;
    --input: 216 34% 17%;

    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;

    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;

    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;

    --ring: 216 34% 17%;

    --radius: 0.5rem;

    --chart-1: theme('colors.orange.300');
    --chart-2: theme('colors.purple.300');
    --chart-3: theme('colors.cyan.300');
    --chart-4: theme('colors.yellow.300');
    --chart-5: theme('colors.green.300');
    --chart-6: theme('colors.pink.300');
  }
}

/*@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings: "rlig" 1, "calt" 1;
  }
}
*/

@layer components {
  .type-headline-1 {
    @apply font-lexend text-4xl font-light leading-tight;
  }
  .type-headline-2 {
    @apply text-2xl font-medium leading-snug;
  }
  .type-headline-3 {
    @apply text-xl font-medium leading-snug;
  }
  .type-headline-4 {
    @apply text-lg font-medium leading-snug;
  }

  .type-large {
    @apply font-regular text-lg leading-normal;
  }
  .type-large-underline {
    @apply type-large underline;
  }
  .type-large-emphasis {
    @apply type-large font-semibold;
  }

  .type-body {
    @apply font-regular text-base leading-normal;
  }
  .type-body-underline {
    @apply type-body underline;
  }
  .type-body-medium {
    @apply type-body font-medium;
  }
  .type-body-medium-underline {
    @apply type-body font-medium underline;
  }
  .type-body-emphasis {
    @apply type-body font-semibold;
  }

  .type-small {
    @apply font-regular text-sm leading-normal;
  }
  .type-small-underline {
    @apply type-small underline;
  }
  .type-small-medium {
    @apply type-small font-medium;
  }
  .type-small-emphasis {
    @apply type-small font-semibold;
  }

  .type-tiny {
    @apply font-regular text-xs leading-normal;
  }
  .type-tiny-underline {
    @apply type-tiny underline;
  }
  .type-tiny-emphasis {
    @apply type-tiny font-semibold;
  }
  .type-tiny-default {
    @apply type-tiny leading-[18px];
  }

  @media (min-width: 768px) {
    .type-headline-1 {
      @apply font-lexend text-5xl font-light leading-tight;
    }
    .type-headline-2 {
      @apply font-regular text-3xl leading-snug;
    }
    .type-headline-3 {
      @apply font-regular text-2xl leading-snug;
    }
    .type-headline-4 {
      @apply text-xl font-medium leading-snug;
    }

    .type-large {
      @apply font-regular text-xl leading-normal;
    }
    .type-large-underline {
      @apply type-large underline;
    }
    .type-large-emphasis {
      @apply type-large font-semibold;
    }
  }

  .modal-bg {
    @apply fixed left-0 top-0 z-20 h-full w-full bg-[#1f2937b3];
  }

  .gradient-text {
    background: var(--primary-primary-gradient, linear-gradient(270deg, #3a65ea 0%, #0a38bf 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

@font-face {
  font-family: 'Lexend';
  src: url('assets/fonts/Readexpro[HEXP,wght].ttf');
  font-weight: 200 700;
  font-style: normal;
}

/* making fontawesome icons work in tiptap */
.icon::before {
  display: inline-block;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}
.icon.icon--warning::before {
  font: var(--fa-font-light);
  content: '\f071';
}
.icon.icon--messages::before {
  font: var(--fa-font-light);
  content: '\f4b6';
}
.icon.icon--example::before {
  font: var(--fa-font-light);
  content: '\f0b1';
}

.ProseMirror-focused {
  outline: none;
}

.ProseMirror {
  padding: 0 40px;
  .is-empty:not(blockquote)::before {
    @apply text-black/40 float-left h-0 pointer-events-none w-full;
  }
  *:not(blockquote)[data-placeholder]::before {
    content: attr(data-placeholder);
  }

  p[data-placeholder] {
    margin-bottom: 32px;
  }

  .ProseMirror-selectednode {
    @apply bg-primary-03;
  }

  *:not(pre) code {
    @apply bg-surface-02 rounded-md p-1  shadow-sm font-mono;
  }
  .tableWrapper {
    @apply my-4;
  }

  table {
    @apply border-collapse border-black/10 rounded box-border w-full;
    @apply dark:border-white/20;

    td,
    th {
      @apply border border-black/10 min-w-[100px] p-2 relative text-left align-top;
      @apply dark:border-white/20;

      &:first-of-type:not(a) {
        @apply mt-0;
      }

      p {
        @apply m-0;

        & + p {
          @apply mt-3;
        }
      }
    }

    th {
      /* @apply font-bold; */
    }

    .column-resize-handle {
      @apply -bottom-[2px] flex pointer-events-none absolute -right-1 top-0 w-2;

      &::before {
        @apply bg-black/20 h-full w-[1px] ml-2;
        @apply dark:bg-white/20;
        content: '';
      }
    }

    .selectedCell {
      @apply bg-black/5 border-black/20 border-double;
      @apply dark:bg-white/10 dark:border-white/20;
    }

    .grip-column,
    .grip-row {
      @apply items-center bg-black/5 cursor-pointer flex justify-center absolute z-10;
      @apply dark:bg-white/10;
    }

    .grip-column {
      @apply w-[calc(100%+1px)] border-l border-black/20 h-3 left-0 -ml-[1px] -top-3;
      @apply dark:border-white/20;

      &:hover,
      &.selected {
        &::before {
          content: '';
          @apply w-2;
        }
      }

      &:hover {
        @apply bg-black/10;
        @apply dark:bg-white/20;

        &::before {
          @apply border-b-2 border-dotted border-black/60;
          @apply dark:border-white/60;
        }
      }

      &.first {
        @apply border-transparent rounded-tl-sm;
      }

      &.last {
        @apply rounded-tr-sm;
      }

      &.selected {
        @apply bg-black/30 border-black/30 shadow-sm;
        @apply dark:bg-white/30 dark:border-white/30;

        &::before {
          @apply border-b-2 border-dotted;
        }
      }
    }

    .grip-row {
      @apply h-[calc(100%+1px)] border-t border-black/20 -left-3 w-3 top-0 -mt-[1px];
      @apply dark:border-white/20;

      &:hover,
      &.selected {
        &::before {
          @apply h-2;
          content: '';
        }
      }
      &:hover {
        @apply bg-black/10;
        @apply dark:bg-white/20;

        &::before {
          @apply border-l-2 border-dotted border-black/60;
          @apply dark:border-white/60;
        }
      }

      &.first {
        @apply border-transparent rounded-tl-sm;
      }

      &.last {
        @apply rounded-bl-sm;
      }

      &.selected {
        @apply bg-black/30 border-black/30 shadow-sm;
        @apply dark:bg-white/30 dark:border-white/30;

        &::before {
          @apply border-l-2 border-dotted;
        }
      }
    }
  }
  [data-type='columns'] {
    @apply grid gap-4 my-4;
    &.has-focus [data-type='column'],
    &:hover [data-type='column'] {
      @apply border-neutral-300 dark:border-neutral-700;
    }

    [data-type='column'].has-focus {
      @apply border-neutral-400 dark:border-neutral-600;
    }

    &.layout-sidebar-left {
      grid-template-columns: 40fr 60fr;
    }

    &.layout-sidebar-right {
      grid-template-columns: 60fr 40fr;
    }

    &.layout-two-column {
      grid-template-columns: 1fr 1fr;
    }
  }

  [data-type='column'] {
    @apply rounded border-2 border-dotted border-transparent p-1;
    @apply grid;
    transition: border 160ms cubic-bezier(0.45, 0.05, 0.55, 0.95);

    &:hover {
      @apply border-neutral-100 dark:border-neutral-900;
    }

    &:has(.is-active),
    &.has-focus {
      @apply border-neutral-100 dark:border-neutral-900;
    }
  }

  [data-type='column'] > * {
    @apply first:mt-0 last:mb-0;
  }

  /* placeholder */
  &.ProseMirror-focused {
    > [data-type='columns'] > [data-type='column'] > p.is-empty.has-focus::before {
      content: 'Type  /  to browse options';
    }
  }

  &.resize-cursor {
    cursor: ew-resize;
    cursor: col-resize;
  }

  blockquote {
    @apply mx-8 px-13 relative bg-surface-bg p-4 border-l-4 rounded-xl;
    border-color: #ddd;
  }
  blockquote:before {
    color: #ddd;
    font-size: 4em;
    position: absolute;
    font-family: Lexend;
    content: '\201C';
    top: -10px;
    left: 10px;
  }

  blockquote::after {
    color: #ddd;
    position: absolute;
    font-family: Lexend;
    font-size: 4em;
    content: '\201D';
    bottom: -40px;
    right: 15px;
  }
  h1 {
    @apply text-3xl;
  }
  h2 {
    @apply text-2xl;
  }
  h3 {
    @apply text-xl;
  }
  h4 {
    @apply text-lg;
  }

  ul,
  ol {
    padding: 0 1rem;
    margin: 1.25rem 1rem 1.25rem 0.4rem;

    li p {
      margin-top: 0.25em;
      margin-bottom: 0.25em;
    }
  }

  ul {
    list-style-type: disc;
  }
  ol {
    list-style-type: decimal;
  }

  ul[data-type='taskList'] {
    list-style: none;
    margin-left: 0;
    padding: 0;

    li {
      align-items: flex-start;
      display: flex;

      > label {
        flex: 0 0 auto;
        margin-right: 0.5rem;
        user-select: none;
      }

      > div {
        flex: 1 1 auto;
      }
    }

    input[type='checkbox'] {
      cursor: pointer;
    }

    ul[data-type='taskList'] {
      margin: 0;
    }
  }
}

.exa {
  font-variation-settings: 'HEXP' 40;
}

html,
body {
  height: 100%;
  overscroll-behavior: none;
}

#root {
  height: 100%;

  /* Toastify styling */
  --toastify-font-family: 'Lexend';
  --toastify-color-success: theme('colors.inverse-bg');
  --toastify-text-color-success: theme('colors.on-inverse-01');
  --toastify-color-error: theme('colors.negative-01');
  --toastify-text-color-error: theme('colors.on-inverse-01');
  --toastify-color-warning: theme('colors.warning-03');
  --toastify-text-color-warning: theme('colors.warning-01');
}

.right-box-shadow {
  box-shadow: 5px 0 5px -5px #333;
}

[type='checkbox']:checked.positive {
  background-image: url('data:image/svg+xml;charset=utf-8,<svg viewBox="0 0 16 16" fill="%2310B981" xmlns="http://www.w3.org/2000/svg"><path d="M12.207 4.793a1 1 0 0 1 0 1.414l-5 5a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L6.5 9.086l4.293-4.293a1 1 0 0 1 1.414 0z"/></svg>');
  background-color: white;
}
[type='checkbox']:checked.selected {
  background-image: url('data:image/svg+xml;charset=utf-8,<svg viewBox="0 0 16 16" fill="%232551DA" xmlns="http://www.w3.org/2000/svg"><path d="M12.207 4.793a1 1 0 0 1 0 1.414l-5 5a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L6.5 9.086l4.293-4.293a1 1 0 0 1 1.414 0z"/></svg>');
  background-color: white;
}
[type='checkbox']:checked.negative {
  background-image: url('data:image/svg+xml;charset=utf-8,<svg viewBox="0 0 16 16" fill="%23F87171" xmlns="http://www.w3.org/2000/svg"><path d="M12.207 4.793a1 1 0 0 1 0 1.414l-5 5a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L6.5 9.086l4.293-4.293a1 1 0 0 1 1.414 0z"/></svg>');
  background-color: white;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

:root {
  --bottom-nav-bottom-padding: 24px;
}

/* fix for excessive spacing in editorjs tools */
.ce-settings,
.ce-toolbox {
  width: 0;
}
